import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Palazzo Vecchio',
    desc =
      `Servizio fotografico al Salone dei 500 a Firenze per i tre secoli dalla morte della granduchessa Marguerite Louise d'Orléans.`,
    subtitle = '',
    roles = ['Fotografo'],
    videoSrc = [],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc} />
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-palazzo-vecchio.jpg'
        alt='Cover per il lavoro a Palazzo Vecchio'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          Nel <em>Salone dei 500</em> a Palazzo Vecchio per i tre secoli dalla
          morte della granduchessa Marguerite Louise d'Orléans e la riscoperta
          dell'<em>Ercole in Tebe</em> a trecentosessanta anni dalla fine dei
          lavori del Teatro della Pergola.
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2 margin-bottom'>
        <span>
          Il successo della giornata di studi "Celestis Origo" dedicata a
          Marguerite Louise d'Orléans è stato possibile grazie allo
          straordinario coinvolgimento dell'Amministrazione Comunale di Firenze.
        </span>
      </p>
      {/* ADD HERE ALL THE PHOTOS */}
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-1.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain work-img-vertical video-0 margin-bottom'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-2.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain work-img-vertical video-1 margin-bottom'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-3.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain work-img-vertical photo-vert-1 margin-bottom'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-5.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain work-img-vertical photo-vert-2'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-4.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain photo-1 margin-bottom'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <StaticImage
        src='../../images/photo/palazzo-vecchio-photo-6.jpg'
        alt='Foto Palazzo Vecchio'
        className='work-img work-img-contain photo-2'
        objectFit='contain'
        imgClassName='img shadow-black'
        quality={100}
      />
      <div className='logo-container'>
        <StaticImage
          src='../../images/logo/logo-medici.png'
          alt='Logo Museo de Medici'
          className='work-logo-bigger logo-1'
        />
        <StaticImage
          src='../../images/logo/logo-firenze.png'
          alt='Logo Comune di Firenze'
          className='work-logo-mid logo-2'
        />
      </div>
    </WorkLayout>
  )
}

export default Work
